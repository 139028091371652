import request from '@/utils/request'

//获取找房区域数据
export function getArea(params) {
  return request({ url: '/web/mch/official/search_housing', method: 'get', params})
}
//获取房源数据
export function getHouse(params) {
  return request({ url: '/web/mch/official/house/type/page', method: 'get', params})
}

//获取房源数据详情
export function getHouseDetail(params) {
  return request({ url: `/web/mch/official/house/type/${params}`, method: 'get'})
}
