<template>
     <div class="container">
        <div class="area_list">
            <div class="area_list_label">地理位置：</div>
            <div class="area_list_value">
                <div class="area_list_item" :class="item.active?'active':''" v-for="(item,index) in areaList" :key="index" @click="changeArea(index, item.area_id)">{{ item.area_name }}</div>
            </div>
        </div>
        <div class="room_list">
            <div class="room_list_item" v-for="(item,index) in houseList" :key="index" @click="toHouseDetail(item.fx_id)">
                <img v-lazy="item.cover_picture?.path" />
                <div class="room_f">
                    <div class="title">{{ item.fx_name }}</div>
                    <div class="info">
                        <span>{{ item.acreage_min }}-{{ item.acreage_max }}㎡</span>
                        <span>|</span>
                        <span>{{ item.rental_type == 1 ? "整租" : "合租" }}</span>
                    </div>
                    <div class="price">
                        <span class="num">{{ item.price_min }}-{{ item.price_max }}</span>
                        <span class="unit">元/月</span>
                    </div>
                    <div class="tag_list">
                        <div class="tag_list_item" v-for="(item2, index2) in item.appliances" :key="index2">{{ item2 }}</div>
                    </div>
                </div>
            </div>
        </div>
        <mobile-Loading v-if="isLoading"/>
     </div>
</template>
<script>
import { getArea, getHouse } from "@/api/lookHouse";
export default {
    data() {
        return {
            areaList: [],
            query: {
                page: 1,
                per_page: 10,
                area_id: null,
            },
            total: 0,
            areaList: [],
            houseList: [],
            //距离底部的距离
            offBottom: 0,
            isEnd: false,
            isLoading: true
        }
    },
    mounted() {
        this.initData();
        window.addEventListener('scroll', this.handleScroll)
        this.offBottom = this.$store.state.distanceToBottom
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        initData() {
            const area = getArea();
            const house = getHouse(this.query);
            Promise.all([area, house]).then((res) => {
                //处理地区
                res[0].data.areas.forEach((item) => {
                    item.active = false;
                });
                this.areaList = res[0].data.areas;
                //处理房源
                this.houseList = res[1].data;
                this.total = res[1].total;
                this.query.page = this.query.page + 1
            })
            .finally((_) => {
                this.isLoading = false
            });
        },
        async initHouse(isPage = false) {
            if(!this.isEnd) {
                this.isEnd = true
                const res = await getHouse(this.query);
                if(!isPage) {
                    this.houseList = res.data;
                }else {
                    this.houseList = [...this.houseList,...res.data]
                }
                this.total = res.total;
                this.query.page = this.query.page + 1
                this.isEnd = false
            }
        },
        changeArea(i,id) {
            this.areaList.forEach((item,index) => {
                if(i == index) {
                    item.active = true
                }else {
                    item.active = false
                }
            })
            this.query.area_id = id;
            this.query.page = 1;
            this.isEnd = false
            this.initHouse(false);
        },
        toHouseDetail(id) {
            this.$router.push(`/houseDetail?id=${id}`)
        },
        handleScroll() {
            const scrollTop = document.documentElement.scrollTop // 滚动条距离顶部的位置
            const clientHeight = document.documentElement.clientHeight // 可视区域的高度
            const scrollHeight = document.documentElement.scrollHeight // 页面的总高度
            const distanceToBottom = scrollHeight - scrollTop - clientHeight // 滚动条距离底部的位置
            if(distanceToBottom <= this.offBottom) {
                if(this.total > (this.query.page - 1) * this.query.per_page) {
                    this.initHouse(true)
                }
            }
        }
    }
}
</script>
<style scoped lang="scss">
.container {
    .area_list {
        position: static;
        padding: 30px;
        box-sizing: border-box;
        display: flex;
        column-gap: 14px;
        &_label {
            color: #333333;
            font-size: 28px;
            font-weight: bold;
            min-width: 130px;
            margin-top: 5px;
        }
        &_value {
            flex: 1;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 16px;
            font-size: 24px;
            .area_list_item {
                padding: 10px 22px;
                box-sizing: border-box;
                border: 1px solid #EEEEEE;
                border-radius: 4px;
                white-space: nowrap;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #999999;
                transition: all 0.3s ease;
            }
            .active {
                color: #FFA71D;
                border: 1px solid #FFA71D;
            }
        }
    }
    .room_list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 18px;
        padding: 10px 30px 50px 30px;
        box-sizing: border-box;
        &_item {
            border: 1px solid #EEEEEE;
            border-radius: 6px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            img {
                width: 100%;
                height: 240px;
                object-fit: cover;
            }
            .room_f {
                padding: 16px;
                box-sizing: border-box;
                color: #999999;
                font-size: 22px;
                .title {
                    color: #333333;
                    font-size: 28px;
                    font-weight: bold;
                }
                .info {
                    display: flex;
                    column-gap: 20px;
                    align-items: center;
                    margin: 10px 0;
                }
                .price {
                    margin-bottom: 10px;
                    .num {
                        font-size: 32px;
                        color: #FF4D4D;
                        font-weight: bold;
                    }
                    .unit {
                        font-size: 24px;
                        color: #666666;
                    }
                }
                .tag_list {
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 10px;
                    row-gap: 10px;
                    &_item {
                        background: #F4F4F4;
                        border: 1px solid #D9D9D9;
                        border-radius: 4px;
                        padding: 6px 8px;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
}
</style>